import { baseUrl } from 'api';

export async function fetchFooter() {
  // attempt to grab it out of localstorage
  const FOOTERKEY = 'si-footer';
  const _footer = localStorage.getItem(FOOTERKEY);
  if (_footer) { return JSON.parse(_footer); }

  // manually grab from the server
  const res = await fetch(`${baseUrl}/footer`);
  const _f =  await res.json();
  localStorage.setItem(FOOTERKEY, JSON.stringify(_f));
  return _f;
}
