import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { StepEntity, SubstepRequirement } from 'store/entities';
import { fetchProvider } from './effects/fetchProvider';

const adapter = createEntityAdapter<StepEntity>();

const slice = createSlice({
  name: 'steps',
  initialState: adapter.getInitialState(),
  reducers: {
    updateStep: adapter.updateOne,

    toggleCompletion: (state, action) => {
      const { payload } = action;
      const { id, isComplete } = payload;
      const { entities } = state;
      const step = entities[id];

      if (!step) {
        return;
      }

      function toggleStep(step: StepEntity, isComplete: boolean) {
        if (!step) {
          return;
        }

        let canToggle = true;

        /**
         * Check to see if the step being toggled has children
         */
        if (step.steps && step.steps.length > 0) {
          const children = step.steps.map((step) => entities[step]);

          /**
           * Check to see if the step requires all children to be complete before being completed
           */
          if (step.substepRequirements === SubstepRequirement.All) {
            /**
             * If any of the children require at least one grandchild to be complete, make sure that is true
             */
            const canToggleChildren = children
              .filter((child) => child?.substepRequirements === SubstepRequirement.One)
              .every((child) => child?.isComplete);

            /**
             * Recursively toggle the step's children to match the parent state
             */
            if (canToggleChildren) {
              children.forEach((child) => {
                if (child) {
                  toggleStep(child, isComplete);
                }
              });
            }

            /**
             * If every child step is complete, we can toggle the parent step
             */
            canToggle = children.every((step) => step?.isComplete);
          } else if (step.substepRequirements === SubstepRequirement.One) {
            /**
             * If the step requires one child, make sure there's at least one child marked as complete
             */
            canToggle = children.find((step) => step?.isComplete) !== undefined;

            /**
             * If we can toggle, that means the parent step is currently marked as complete and being changed to incomplete.
             * Therefore, we should update all the children to match.
             */
            if (canToggle) {
              children.forEach((child) => {
                if (child) {
                  toggleStep(child, isComplete);
                }
              });
            }
          }
        }

        if (canToggle) {
          step.isComplete = isComplete;
        }
      }

      toggleStep(step, isComplete);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProvider.fulfilled, (state, action) => {
      adapter.upsertMany(state, action.payload.steps);
    });
  }
});

export const { toggleCompletion, updateStep } = slice.actions;

export const {
  selectById: selectStepById,
  selectIds: selectStepIds,
  selectEntities: selectStepEntities,
  selectAll: selectAllSteps,
  selectTotal: selectTotalSteps
} = adapter.getSelectors((state: RootState) => state.steps);

export const selectParentStep = createSelector(selectStepById, selectStepEntities, (step, steps) => {
  if (step && step.parentId) {
    return steps[step.parentId];
  }
});

export const selectIsParentSatisfied = createSelector(selectStepById, selectStepEntities, (step, steps) => {
  if (step && step.parentId) {
    const parent = steps[step.parentId];

    if (parent && parent.steps) {
      const children = parent.steps.map((step) => steps[step]);

      if (parent.substepRequirements === SubstepRequirement.All) {
        return children.every((step) => step?.isComplete);
      } else if (parent.substepRequirements === SubstepRequirement.One) {
        return children.find((step) => step?.isComplete);
      }
    }
  }
});

export const selectIsStepSatisfied = createSelector(selectStepById, selectStepEntities, (step, steps) => {
  if (step && step.steps && step.steps.length > 0) {
    const children = step.steps.map((step) => steps[step]);

    if (step.substepRequirements === SubstepRequirement.All) {
      return children.every((step) => step?.isComplete);
    } else if (step.substepRequirements === SubstepRequirement.One) {
      return children.find((step) => step?.isComplete);
    }

    return true;
  }

  return true;
});

export default slice.reducer;
