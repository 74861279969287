import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { LoginForm } from 'forms/LoginForm';
import { rootPath } from 'pages';
import { useState } from 'react';

export const loginPath = () => '/login';

export function Login() {
  const [isAuthed, setisAuthed] = useState(false);
  const title = 'Sign in';

  // const { isAuthenticated } = useAuthentication();

  // if (isAuthenticated) {
  //   return <Redirect to={rootPath()} />;
  // }

  function isLoggedIn() {
    setisAuthed(true);
  }

  if (isAuthed) {
    return <Redirect to={rootPath()} />;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <h1 className="mb-5 text-center">Interactive Payor Criteria Checklist</h1>

      <h2 className="mb-5 text-center">{title}</h2>

      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-4">
          <LoginForm loginResult={isLoggedIn}/>
        </div>
      </div>
    </>
  );
}
