import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import type { ChecklistEntity, InjectionChecklistEntity, ProviderEntity, SectionEntity, StepEntity } from 'store/entities';
import { fetchProviderBySlug } from 'api/providers';
import { providerEntity } from 'store/entities';

export const fetchProvider = createAsyncThunk('providers/fetchProvider', async (slug: string) => {
  let data = await fetchProviderBySlug(slug);
  const normalized = normalize<
    any,
    {
      providers: Record<string, ProviderEntity>;
      checklists: Record<string, ChecklistEntity>;
      injectionchecklists: Record<string, InjectionChecklistEntity>;
      sections: Record<string, SectionEntity>;
      steps: Record<string, StepEntity>;
    }
  >(data, providerEntity);

  return normalized.entities;
});
