import { Helmet } from 'react-helmet';
import { ProviderForm } from 'forms/ProviderForm';
import UserApi from '../api/user';
import { Redirect } from 'react-router-dom';

export const rootPath = () => '/';

export function Root() {
  const title = 'Select State & Insurance Provider';

  if (!UserApi.getUser() && !UserApi.getLocalUser()?.jwt) {
    return <Redirect to={"/logout"} />;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="row justify-content-center mb-5">
        <div className="col-md-6">
          <h4>
            Welcome to SI-BONE&rsquo;s interactive iFuse payor criteria &ldquo;checklist&rdquo; tool. This tool is
            designed for use with patients who may be iFuse surgical candidates. Select the state and insurance provider
            from the list below to view payor-specific requirements for medical necessity and favorable coverage.
          </h4>
        </div>
      </div>

      <h1 className="mb-5 text-center">{title}</h1>

      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-4">
          <ProviderForm />
        </div>
      </div>

      <div className="row mt-5">
        <div className="col">
          <p className="text-center mb-0">
            Contact SI-BONE for more information: (800) 710-8511 |{' '}
            <a href="mailto:PICS@si-bone.com">PICS@si-bone.com</a> |{' '}
            <a href="https://si-bone.com/" target="_blank" rel="noreferrer">
              si-bone.com
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
