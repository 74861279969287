import qs from 'qs';
import { baseUrl, defaultQuery } from 'api';

// Helper function to get the JWT token
function getUserFromLocalStorage() {
  const userString = localStorage.getItem('usr'); // Replace 'user' with the actual key used
  if (userString) {
    return JSON.parse(userString);
  }
  return null;
}

function getAuthToken() {
  const user = getUserFromLocalStorage();
  return user ? user.jwt : null;
}


// Helper function to create headers with Authorization
function createAuthHeaders() {
  const token = getAuthToken();
  return {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  };
}

export async function fetchStates() {
  const query = qs.stringify(defaultQuery);
  const headers = createAuthHeaders();

  const res = await fetch(`${baseUrl}/states?${query}`, { headers });
  return await res.json();
}

export async function fetchStateProviders(id: number | string) {
  const headers = createAuthHeaders();
  let res;

  if (id === "default" || id === "all") {
    // Fetch all providers to be displayed
    res = await fetch(`${baseUrl}/providers`, { headers });
    const data = await res.json();
    return {
      providers: data,
      id: "all"
    }
  } else {
    // Fetch only providers with specified state id
    res = await fetch(`${baseUrl}/states/${id}`, { headers });
  }
  return await res.json();
}