import { fetchStateProviders, fetchStates } from 'api/states';
import { useQuery } from 'react-query';
import { IState } from 'types';

export function useStatesQuery() {
  return useQuery<IState[]>('states', fetchStates);
}

// If stateId is "all" there is no state filter applied toward the list of providers
export function useStateByIdQuery(stateId: number | string) {
  return useQuery<IState>(['state', stateId], () => fetchStateProviders(stateId), {
    enabled: !!stateId
  });
}
