import ReactMarkdown from 'react-markdown';
import { useFooterQuery } from 'hooks/useFooter';
import styles from './Footer.module.scss';

export function Footer() {
  const { data } = useFooterQuery();
  if (!data) { return null; }

  const { text } = data;

  return (
    <div className={styles.footer}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-xl-6">
            <div className={styles.content}>
              <ReactMarkdown>{text}</ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
