import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import type { ProviderEntity } from 'store/entities';
import { RootState } from 'store';
import { selectStepEntities } from './steps';
import { fetchProvider } from './effects/fetchProvider';

const adapter = createEntityAdapter<ProviderEntity>({
  selectId: (provider) => provider.slug
});

const slice = createSlice({
  name: 'providers',
  initialState: adapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProvider.fulfilled, (state, action) => {
      adapter.upsertMany(state, action.payload.providers);
    });
  }
});

export const {
  selectById: selectProviderBySlug,
  selectIds: selectProviderSlugs,
  selectEntities: selectProviderEntities,
  selectAll: selectAllProviders,
  selectTotal: selectTotalProviders
} = adapter.getSelectors((state: RootState) => state.providers);

export const selectCompletedSteps = createSelector(selectProviderBySlug, selectStepEntities, (provider, steps) => {
  if (provider && provider.stepIds) {
    // Should return entire map of only complete checklist items
    return provider.stepIds
      .map((stepId) => steps[stepId])
      .filter((step) => step?.isComplete)
      .map((step) => step?.id);
  }
});

export const selectInjectionCompletedSteps = createSelector(selectProviderBySlug, selectStepEntities, (provider, steps) => {
  if (provider && provider.injectionStepIds) {
    // Should return entire map of only complete checklist items
    return provider.injectionStepIds
      .map((stepId) => steps[stepId])
      .filter((step) => step?.isComplete)
      .map((step) => step?.id);
  }
});

export default slice.reducer;
