export function ChecklistStatus(props: { incomplete: number; total: number }) {
  const { incomplete, total } = props;

  if (incomplete === total) {
    return <span>{total}</span>;
  }

  return (
    <span>
      <s>{total}</s> {incomplete}
    </span>
  );
}
