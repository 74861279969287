import {
  selectAllChecklistSteps,
  selectChecklistById,
  selectCompleteChecklistSteps,
  selectIncompleteChecklistSteps
} from 'store/features/checklists';
import { useAppSelector } from './useAppSelector';

export function useChecklist(id: number) {
  const checklist = useAppSelector((state) => selectChecklistById(state, id));
  const incompleteSteps = useAppSelector((state) => selectIncompleteChecklistSteps(state, id));
  const completeSteps = useAppSelector((state) => selectCompleteChecklistSteps(state, id));
  const allSteps = useAppSelector((state) => selectAllChecklistSteps(state, id));

  return {
    checklist,
    incompleteSteps,
    completeSteps,
    allSteps
  };
}
