import { selectSectionById } from 'store/features/sections';
import { useAppSelector } from './useAppSelector';

export function useSection(id: number) {
  const section = useAppSelector((state) => selectSectionById(state, id));

  return {
    section
  };
}
