import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuthentication } from 'hooks/useAuthentication';
import { loginPath } from 'pages/login';
import UserApi from '../api/user';

export const logoutPath = () => '/logout';

export function Logout() {
  const { logout } = useAuthentication();

  useEffect(() => {
    UserApi.logout();
    logout();
    window.localStorage.clear();
  }, [logout]);

  return <Redirect to={loginPath()} />;
}
