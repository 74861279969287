import throttle from 'lodash/throttle';
import { configureStore } from '@reduxjs/toolkit';

import authentication from './features/authentication';
import checklists from './features/checklists';
import injectionchecklists from './features/injectionChecklists';
import providers from './features/providers';
import sections from './features/sections';
import steps from './features/steps';
import { loadState, saveState } from './localStorage';

const preloadedState = loadState();

export const store = configureStore({
  preloadedState,
  reducer: {
    authentication,
    checklists,
    injectionchecklists,
    providers,
    sections,
    steps
  }
});

store.subscribe(
  throttle(() => {
    saveState({
      authentication: store.getState().authentication
    });
  }, 1000)
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
