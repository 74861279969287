import { useAppDispatch } from './useAppDispatch';
import {
  selectIsStepSatisfied,
  selectIsParentSatisfied,
  selectParentStep,
  selectStepById,
  toggleCompletion
} from 'store/features/steps';
import { useAppSelector } from './useAppSelector';
import { useCallback, useEffect } from 'react';

export function useStep(stepId: string) {
  const dispatch = useAppDispatch();
  const step = useAppSelector((state) => selectStepById(state, stepId));
  const isStepSatisfied = useAppSelector((state) => selectIsStepSatisfied(state, stepId));
  const isParentSatisfied = useAppSelector((state) => selectIsParentSatisfied(state, stepId));
  const parentStep = useAppSelector((state) => selectParentStep(state, stepId));
  const parentStepId = parentStep?.id;

  const toggleIsComplete = useCallback(
    (id: string, isComplete: boolean) => {
      dispatch(toggleCompletion({ id, isComplete }));
    },
    [dispatch]
  );

  const updateIsComplete = useCallback(
    (id: string, isComplete: boolean) => {
      // Commented out line Fixes boxes becoming unchecked when toggling between checklists. Did not seem to break anything else so leaving this in here in case we need to turn back on.
      //dispatch(updateStep({ id, changes: { isComplete } }));
    },
    []
    // dispatch : removed to avoid console warning
  );

  useEffect(() => {
    if (parentStepId) {
      updateIsComplete(parentStepId, Boolean(isParentSatisfied));
    }
  }, [isParentSatisfied, parentStepId, updateIsComplete]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();

      if (!step) {
        return;
      }

      toggleIsComplete(stepId, !step.isComplete);
    },
    [step, stepId, toggleIsComplete]
  );

  return {
    handleClick,
    step,
    isStepSatisfied
  };
}
