import { PropsWithChildren } from 'react';
import styles from './Content.module.scss';

export function Content(props: PropsWithChildren<{}>) {
  const { children } = props;

  return (
    <div className={styles.content}>
      <div className="container">
        <div className="row">
          <div className="col">{children}</div>
        </div>
      </div>
    </div>
  );
}
