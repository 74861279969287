import { FormEvent, useState, useEffect } from 'react';
import { useAuthentication } from 'hooks/useAuthentication';
import { useInput } from 'hooks/useInput';
import UserApi from '../api/user';

type LoginProps = {
  loginResult: Function;
};

export function LoginForm(props:LoginProps) {
  const { login } = useAuthentication();

  const username = useInput('');
  const password = useInput('');

  // Save-off values for re-use when checking login credentials
  const user = username.value;//.replace(/\s/g, '').toLowerCase();
  const pass = password.value;//.replace(/\s/g, '').toLowerCase();

  const [error, setError] = useState<null | string>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Three valid logins, same password for all
  // switch these logins to strapi auth endpoint with a specific role
  // const isValid = 
  //   (user === 'ifuse' && pass === 'ifuse123') ||
  //   (user === 'si-bone' && pass === 'ifuse123') ||
  //   (user === 'pics' && pass === 'ifuse123');
  
  useEffect(() => {
    let userApiSub:any;
    userApiSub = UserApi.stateChanged.subscribe((s) => {
      if (s.invalid) {
        // api returned but got an invalid login or server error
        // password.setValue('');
        setIsSubmitting(false);
        setError('Invalid username or password');
      }
      if (!s.invalid && s.user) {
        // api returned and we're good to go
        setIsSubmitting(false);
        login();
        if (props.loginResult) props.loginResult();
      }
      setIsSubmitting(false);
    });
    return () => { userApiSub.unsubscribe(); }
  });
  //TODO: get rid of console nag about componentWillMount

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setError(null);

    if (user === "si-bone" && pass === "ifuse123") {
      setError('Time to reset your password. Click on “Request Access” to request your new password.');
      return;
    }

    setIsSubmitting(true);
  
    UserApi.login(user, pass);
    return;
  }

  return (
    <form onSubmit={handleSubmit} className="form-login">
      {error ? (
        <div className="alert alert-danger text-center" role="alert">
          {error}
        </div>
      ) : null}

      <input
        className="form-control mb-5"
        disabled={isSubmitting}
        onChange={username.handleChange}
        placeholder="Username"
        type="text"
        value={username.value}
      />

      <input
        className="form-control mb-5"
        disabled={isSubmitting}
        onChange={password.handleChange}
        placeholder="Password"
        type="password"
        value={password.value}
      />

      <button className="w-100 btn btn-primary rounded-pill text-uppercase" 
              disabled={isSubmitting || user.length < 1 || pass.length < 1} type="submit">
        {isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span className="visually-hidden">Loading&hellip;</span>
          </>
        ) : (
          <>Sign in</>
        )}
      </button>

      <div className="text-center mt-3">
        <a href="https://si-bone.com/providers/resources/reimbursement/payors" className="btn btn-link">
          Request Access
        </a>
      </div>
    </form>
  );
}
