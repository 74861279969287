import { useEffect } from 'react';
import { fetchProvider } from 'store/features/effects/fetchProvider';
import { selectCompletedSteps, selectInjectionCompletedSteps, selectProviderBySlug } from 'store/features/providers';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

export function useProvider(providerSlug: string) {
  const dispatch = useAppDispatch();
  const provider = useAppSelector((state) => selectProviderBySlug(state, providerSlug));
  const completedSteps = useAppSelector((state) => selectCompletedSteps(state, providerSlug));
  const injectionCompletedSteps = useAppSelector((state) => selectInjectionCompletedSteps(state, providerSlug));

  useEffect(() => {
    dispatch(fetchProvider(providerSlug));
  }, [dispatch, providerSlug]);

  return {
    provider,
    completedSteps,
    injectionCompletedSteps
  };
}
