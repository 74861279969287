import { Redirect, Route, RouteProps, Switch, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Content } from 'components/Content';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { Login, loginPath } from 'pages/login';
import { Logout, logoutPath } from 'pages/logout';
import { NotFound } from 'pages/404';
import { Provider, providerPath } from 'pages/providers/slug';
import { Root, rootPath } from 'pages';
import { useAnalytics } from 'hooks/useAnalytics';
import { useAuthentication } from 'hooks/useAuthentication';
import styles from './Layout.module.scss';

export function Layout() {
  useAnalytics();
  const { pathname } = useLocation();

  return (
    <>
      <Helmet defaultTitle="SI-BONE" titleTemplate={`%s \u2014 SI-BONE`} />

      <div className={styles.layout}>
        <Header />

        <Content>
          <Switch>
            <PrivateRoute exact path={providerPath(':slug')}>
              <Provider />
            </PrivateRoute>

            <Route exact path={loginPath()}>
              <Login />
            </Route>

            <Route exact path={logoutPath()}>
              <Logout />
            </Route>

            <PrivateRoute exact path={rootPath()}>
              <Root />
            </PrivateRoute>

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Content>

        {pathname.match(/providers/) ? <Footer /> : null}
      </div>
    </>
  );
}

function PrivateRoute(props: RouteProps) {
  const { children, ...rest } = props;
  const { isAuthenticated } = useAuthentication();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: loginPath(),
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
