import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import styles from './../pages/providers/Modal.module.scss';
import { ChecklistType } from 'store/entities';

export function EmailForm(props: any) {
  const noReplyAddress = "no-reply@si-bone.com";
  const fusionSubjectLine = "Your Insurance Criteria for Minimally Invasive SI Joint Fusion";
  const injectionSubjectLine = "Your Insurance Criteria for SI Joint Injections";
  const invalidMessage = "Please input a valid email. Recipient address must contain at least 5 characters and the @ symbol.";

  const [recipient, setRecipient] = useState(null);
  const [isValid, setIsValid] = useState(true);
  // Loading for emitting email
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (recipient && props.isLoading) {
      setIsLoading(true);
    }
  }, [props.isLoading, recipient]);

  function validateEmail(e: any) {
    var emailValidation = new RegExp(/^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i);
    const recipientInput = e.currentTarget.value;
    const inputIsValid = emailValidation.test(recipientInput) ? true : false;
    
    // Update state after checking email validity
    if (inputIsValid) {
      setIsValid(inputIsValid);
      setRecipient(recipientInput);
    } else {
      // Show visual invalid email flag until at least 5 chars & and the @ symbol
      if (recipientInput.length >= 5 || recipientInput.includes("@")) {
        setIsValid(inputIsValid);
        setRecipient(null);
      }
    }
  }
  return (
    <>
      <div className={styles.emailModal}>
        <form>
          <div className="form-group">
            <label>From:</label>
            <input
              className="form-control"
              type="text"
              disabled
              defaultValue={noReplyAddress} />
          </div>

          <div className="form-group">
            <label>To:</label>
            <input
              className="form-control"
              type="text"
              placeholder="Recipient Address"
              onChange={(e) => { if (e !== null) { validateEmail(e) } }} />

            {!isValid && <div style={{ fontSize: '.8em', margin: '0.5em', color: "#F55" }}>{invalidMessage}</div>}
            {isValid && <div style={{ fontSize: '.8em', margin: '0.5em', color: "#F55" }}>&nbsp;</div>}
          </div>
          <div className="form-group">
            <label>Subject:</label>
            <textarea
              className="form-control"
              disabled
              defaultValue={props.checklistType === ChecklistType.Fusion ? fusionSubjectLine : injectionSubjectLine}
              rows={2} />
          </div>
        </form>

        {/* Patient Notes */}
        {/* <label className="form-label">
                <FontAwesomeIcon icon={faBookMedical} />&nbsp;Patient Notes
                </label>
                <input id="patient-notes" type="text" className="form-control" placeholder="Patient Notes" />
                <div className="ms-3">{ }</div>
              <br /> */}

        {/* Disclaimer */}
        {/* <label className="form-label">
                Disclaimer
                </label>
              <p className="mb-0">{legalDisclaimer}</p> */}

        {/* Close and Send Buttons */}
        <div style={{width: '100%', textAlign: "right", paddingTop: "10px"}}>
          <Button
            color="secondary"
            className="w-30 btn btn-primary rounded-pill m-1"
            onClick={() => { if (props.onCloseModal) { props.onCloseModal() } }
            }
          >
            Close
          </Button>
          <Button
            color="primary"
            className="w-30 btn btn-primary rounded-pill m-1"
            onClick={() => {
              if (props.onSubmit) {
                if (isValid && recipient) { props.onSubmit(recipient) }
              }
            }
            }
            disabled={isLoading || (!isValid || !recipient)}
          >
            {isLoading ? "Sending" : "Send"}
          </Button>
        </div>
      </div >
    </>
  )
}