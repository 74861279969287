import { ChecklistStatus } from './ChecklistStatus';
import { Section } from './Section';
import { useChecklist } from 'hooks/useChecklist';
import { useEffect, useState } from 'react';

export type ChecklistProps = {
  checklistId: number;
  policyName: string;
  sendValues: Function;
  patientName: string;
};

export function Checklist(props: ChecklistProps) {
  const { checklistId, policyName } = props;
  const { checklist, incompleteSteps } = useChecklist(checklistId);

  const [patientName, setPatientName] = useState(props.patientName);

  useEffect(() => {
    // Whenever patientName changes, emit down to Email Form through sendValues()
    if (props.sendValues) {
      props.sendValues({ patientName });
    }
  }, [patientName, props]);

  if (!checklist) {
    return null;
  }

  const date = new Date().toLocaleString([], { year: 'numeric', month: 'long', day: 'numeric' });

  const { sections } = checklist;

  return (
    <div>
      <p>
        <ChecklistStatus incomplete={Number(incompleteSteps)} total={checklist.stepIds.length} /> medical necessity
        criteria to support positive coverage decisions for {policyName}
      </p>

      <p>
        Please review the payor&rsquo;s criteria below for {policyName}. Check only the criteria that are present in the
        patient&rsquo;s records, or have otherwise been documented. Satisfied criteria will appear as a &ldquo;checked
        box&rdquo; in gray strike-through font. A notes section is available at the bottom to identify
        &ldquo;unchecked&rdquo; documentation still needed, or to provide additional instructions or details.
      </p>

      <div className="my-4">
        <label htmlFor="patient-name" className="form-label">
          Patient Name
        </label>

        <div className="d-flex align-items-center">
          <div className="flex-grow-1">
            <input id="patient-name" onBlur={(e) => {
              setPatientName(e.currentTarget.value)
            }} type="text" className="form-control" placeholder="Add patient name&hellip;" defaultValue={patientName} />
          </div>

          <div className="ms-3">{date}</div>
        </div>
      </div>

      {sections.map((sectionId) => (
        <Section key={sectionId} id={sectionId} />
      ))}
    </div>
  );
}
