import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { SectionEntity } from 'store/entities';
import { fetchProvider } from './effects/fetchProvider';

const adapter = createEntityAdapter<SectionEntity>();

const slice = createSlice({
  name: 'sections',
  initialState: adapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProvider.fulfilled, (state, action) => {
      adapter.upsertMany(state, action.payload.sections);
    });
  }
});

export const {
  selectById: selectSectionById,
  selectIds: selectSectionIds,
  selectEntities: selectSectionEntities,
  selectAll: selectAllSections,
  selectTotal: selectTotalSections
} = adapter.getSelectors((state: RootState) => state.sections);

export default slice.reducer;
