import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

type AuthenticationState = {
  isAuthenticated: boolean;
};

const initialState: AuthenticationState = {
  isAuthenticated: false
};

export const authentication = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    login: (state) => {
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.isAuthenticated = false;
    }
  }
});

export const { login, logout } = authentication.actions;

export const selectIsAuthenticated = (state: RootState) => {
  return state.authentication.isAuthenticated;
};

export default authentication.reducer;
