import {
  selectAllChecklistSteps,
  selectChecklistById,
  selectCompleteChecklistSteps,
  selectIncompleteChecklistSteps
} from 'store/features/injectionChecklists';
import { useAppSelector } from './useAppSelector';

export function useInjectionChecklist(id: number) {
  const injectionChecklist = useAppSelector((state) => selectChecklistById(state, id));
  const injectionsIncompleteSteps = useAppSelector((state) => selectIncompleteChecklistSteps(state, id));
  const injectionCompleteSteps = useAppSelector((state) => selectCompleteChecklistSteps(state, id));
  const injectionAllSteps = useAppSelector((state) => selectAllChecklistSteps(state, id));

  return {
	injectionChecklist,
	injectionsIncompleteSteps,
	injectionCompleteSteps,
	injectionAllSteps
  };
}
