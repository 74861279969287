import TagManager from 'react-gtm-module';
import { BrowserRouter as Router } from 'react-router-dom';
import { Layout } from 'components/Layout';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { store } from 'store';
import 'styles/main.scss';

const queryClient = new QueryClient();

TagManager.initialize({
  // TODO: NUKE KEY DEFAULT IF NOT TESTING GA
  // gtmId: process.env.REACT_APP_GA_KEY ? process.env.REACT_APP_GA_KEY : 'GTM-W3B57C9'
  gtmId: process.env.REACT_APP_GA_KEY ? process.env.REACT_APP_GA_KEY : 'GTM-TJ37PPM'
});

function App() {
  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <Router>
            <Layout />
          </Router>
        </Provider>
      </QueryClientProvider>
    </StrictMode>
  );
}

render(<App />, document.getElementById('root'));
