import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'reactstrap';
import { useState } from 'react';

import styles from './Step.module.scss';
import { useStep } from 'hooks/useStep';

type StepProps = {
  id: string;
};

export function Step(props: StepProps) {
  const { id } = props;
  const { step, handleClick } = useStep(id);

  const [showInfoModal, setShowInfoModal] = useState(false);       // toggle modal desktop

  if (!step) { return null; }

  function toggle() {
    setShowInfoModal(!showInfoModal)
  }

  let help_image:any = [];
  const { isComplete, steps, text, help_text } = step;
  help_image = step.help_image;

  return (
    <li onClick={handleClick} className={styles.step}>
      <span
        className={classNames({
          'text-muted text-decoration-line-through': isComplete
        })}
      >
        <FontAwesomeIcon
          icon={Boolean(isComplete) ? faCheckSquare : faSquare}
          listItem
          fixedWidth
          style={{ top: '1px' }}
        />
        {help_image && help_image.url && (
          <>
            <Tooltip 
              placement="right-start" 
              isOpen={showInfoModal} 
              delay={{ show: 200, hide: 100 }}
              target={`TooltipExample-${id}`} 
              toggle={toggle} 
              style={{ backgroundColor: '#FFF', padding: '10px', borderRadius: '5px', border: 'solid 1px #555', maxWidth: '900px' }}
            >
              {(help_image?.url && (!help_text || help_text === '')) && (
                <>
                  {console.log(help_image?.url)}
                  <img src={(process.env.REACT_APP_API_BASE_URL?.replace(/\/api$/, '') || '') + help_image.url} alt={help_image.name} width={help_image.width} height={help_image.height} />
                </>
              )}
            </Tooltip>
            <FontAwesomeIcon
              icon={faInfoCircle}
              fixedWidth
              style={{
                display: 'block', 
                float: 'left', 
                marginRight: '5px', 
                color: Boolean(isComplete) ? "#555" : "#4e6f11" 
              }}
              id={`TooltipExample-${id}`}
            />
          </>
        )}
        {(help_text && help_text !== null && help_text !== '') && (
          <>
            <Tooltip 
              placement="right-start" 
              isOpen={showInfoModal} 
              delay={{ show: 200, hide: 100 }}
              target={`TooltipExample-${id}`} 
              toggle={toggle} 
              style={{ backgroundColor: '#FFF', padding: '10px', borderRadius: '5px', border: 'solid 1px #555', maxWidth: '900px' }}
            >
              <p style={{marginBottom: 0}}>{help_text}</p>
            </Tooltip>
            <FontAwesomeIcon
              icon={faInfoCircle}
              fixedWidth
              style={{
                display: 'block', 
                float: 'left', 
                marginRight: '5px', 
                color: Boolean(isComplete) ? "#555" : "#4e6f11" 
              }}
              id={`TooltipExample-${id}`}
            />
          </>
        )}
        <ReactMarkdown>{text}</ReactMarkdown>
      </span>

      {steps && steps.length > 0 ? (
        <ul className="fa-ul">
          {steps.map((substepId) => (
            <Step key={substepId} id={substepId} />
          ))}
        </ul>
      ) : null}
    </li>
  );
}
