import TagManager from 'react-gtm-module';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import UserApi from '../api/user';

export function useAnalytics() {
  const location = useLocation();
  useEffect(() => {
    let _usr = UserApi.getLocalUser();
    let _data = { 
      event: 'pageview', 
      userId: _usr ? _usr.user.username : null 
    };
    TagManager.dataLayer({ dataLayer: _data });
  }, [location]);
}
