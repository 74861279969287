import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { ChecklistType } from 'store/entities';
import styles from './ChecklistToggle.module.scss';

type ChecklistToggleProps = {
	selectedChecklist: ChecklistType;
	updateChecklistType: () => void;
};

export function ChecklistToggle(props: ChecklistToggleProps) {
	return (
		<div className={styles.toggleContainer}>
			<div className="btn-group" role="group">
				<button 
					type="button"
					onClick={props.updateChecklistType}
					className={`${styles.btnLeft} ${props.selectedChecklist === ChecklistType.Fusion ? styles.btnActive : ''}`}>
						<FontAwesomeIcon 
							icon={faCheckCircle} 
							className={`${styles.icon} ${props.selectedChecklist === ChecklistType.Fusion ? styles.iconActive : styles.iconInactive}`} />
						&nbsp;SI Joint Fusion
				</button>
				<button 
					type="button"
					onClick={props.updateChecklistType}
					className={`${styles.btnRight} ${props.selectedChecklist === ChecklistType.Injection ? styles.btnActive : ''}`}>
					
					<FontAwesomeIcon 
						icon={faCheckCircle} 
						className={`${styles.icon} ${props.selectedChecklist === ChecklistType.Injection ? styles.iconActive : styles.iconInactive}`} />
						&nbsp;SI Joint Injections
				</button>
			</div>
			<div className={styles.toggleInstructions}>
				<p>Click here to toggle between the two Interactive Payor Criteria “Checklist” Tools</p>
			</div>
		</div>
	);
}