import { baseUrl } from 'api';

// Helper function to get the JWT token
function getUserFromLocalStorage() {
  const userString = localStorage.getItem('usr'); // Replace 'usr' with the actual key used
  if (userString) {
    return JSON.parse(userString);
  }
  return null;
}

function getAuthToken() {
  const user = getUserFromLocalStorage();
  return user ? user.jwt : null;
}

// Helper function to create headers with Authorization
function createAuthHeaders() {
  const token = getAuthToken();
  return {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  };
}

export async function fetchProviderBySlug(slug: string) {
  const headers = createAuthHeaders();
  const res = await fetch(`${baseUrl}/providers/${slug}`, { headers });
  return await res.json();
}

export async function fetchAllProviders() {
  const headers = createAuthHeaders();
  const res = await fetch(`${baseUrl}/providers`, { headers });
  return await res.json();
}
