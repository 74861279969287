import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit';

import type { ChecklistEntity } from 'store/entities';
import { RootState } from 'store';
import { selectStepEntities } from './steps';
import { fetchProvider } from './effects/fetchProvider';

const adapter = createEntityAdapter<ChecklistEntity>();

const slice = createSlice({
  name: 'checklists',
  initialState: adapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProvider.fulfilled, (state, action) => {
      adapter.upsertMany(state, action.payload.checklists);
    });
  }
});

export const {
  selectById: selectChecklistById,
  selectIds: selectChecklistIds,
  selectEntities: selectChecklistEntities,
  selectAll: selectAllChecklists,
  selectTotal: selectTotalChecklists
} = adapter.getSelectors((state: RootState) => state.checklists);

export const selectIncompleteChecklistSteps = createSelector(
  selectChecklistById,
  selectStepEntities,
  (checklist, steps) => {
    if (checklist && checklist.stepIds) {
      return checklist.stepIds.map((stepId) => steps[stepId]).filter((step) => !step?.isComplete).length;
    }
  }
);

export const selectCompleteChecklistSteps = createSelector(
  selectChecklistById,
  selectStepEntities,
  (checklist, steps) => {
    if (checklist && checklist.stepIds) {
      return checklist.stepIds.map((stepId) => steps[stepId]).filter((step) => step?.isComplete);
    }
  }
);

export const selectAllChecklistSteps = createSelector(selectChecklistById, selectStepEntities, (checklist, steps) => {
  if (checklist && checklist.stepIds) {
    // Should return map of checklist with their id's
    return checklist.stepIds.map((stepId) => steps[stepId]);
  }
});

export default slice.reducer;
