import { Link } from 'react-router-dom';
import { logoutPath } from 'pages/logout';
import { rootPath } from 'pages';
import { useAuthentication } from 'hooks/useAuthentication';
import styles from './Header.module.scss';

export function Header() {
  const { isAuthenticated } = useAuthentication();

  return (
    <header className={styles.header}>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <Link to={rootPath()} title="SI-BONE">
                  <img
                    width="153"
                    height="57"
                    src="/images/logo.svg"
                    alt="SI-BONE Logo"
                    title="site.v2"
                  />
                </Link>
              </div>
              {isAuthenticated ? (
                <Link
                  className="btn btn-dark rounded-pill fw-normal px-3"
                  to={logoutPath()}
                >
                  Sign out
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
