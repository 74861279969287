import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useProvider } from 'hooks/useProvider';
import { Dashboard } from '../../components/Dashboard';
import { ProviderDetails } from '../../components/Content/ProviderDetails';
import UserApi from '../../api/user';
import { Redirect } from 'react-router-dom';
import { ChecklistType } from 'store/entities';

export const providerPath = (slug: string) => `/providers/${slug}`;

type ProviderParams = {
  slug: string;
};

export function Provider() {
  const { slug } = useParams<ProviderParams>();
  const { provider, completedSteps, injectionCompletedSteps } = useProvider(slug);
  const [selectedChecklistType, setSelectedChecklistType] = useState<ChecklistType | null>(null);
  
  function selectFusionChecklist() {
    setSelectedChecklistType(ChecklistType.Fusion)
  }
  
  function selectInjectionChecklist() {
    setSelectedChecklistType(ChecklistType.Injection)
  }

  if (!UserApi.getUser() && !UserApi.getLocalUser()?.jwt) {
    return <Redirect to={"/logout"} />;
  }

  if (provider) {
    if (selectedChecklistType) {
      return <ProviderDetails provider={provider} completedSteps={completedSteps} injectionCompletedSteps={injectionCompletedSteps} selectedChecklistType={selectedChecklistType} />
    } else {
      return <Dashboard provider={provider} selectFusionChecklistType={() => selectFusionChecklist} selectInjectionChecklistType={() => selectInjectionChecklist} />;
    }
  }

  // Returns null element on first pass should provider not be accessible yet
  // TODO: refactor to never return null component in the first place - wait for Provider to be populated before returning.
  return null
}
