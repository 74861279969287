import { useAppDispatch } from './useAppDispatch';
import { login, logout, selectIsAuthenticated } from 'store/features/authentication';
import { useAppSelector } from 'hooks/useAppSelector';

export function useAuthentication() {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  // if isAuthenticated then GA track the userID into a GA secondary dimension

  return {
    isAuthenticated,
    login() {
      return dispatch(login());
    },
    logout() {
      return dispatch(logout());
    }
  };
}
