import { Step } from './Step';
import { useSection } from 'hooks/useSection';
import styles from './Section.module.scss';

type SectionProps = {
  id: number;
};

export function Section(props: SectionProps) {
  const { section } = useSection(props.id);

  if (!section) {
    return null;
  }

  const { name, steps } = section;

  return (
    <section className={styles.section}>
      <h1>{name}</h1>

      <ul className="fa-ul">
        {steps.map((stepId) => (
          <Step key={stepId} id={stepId} />
        ))}
      </ul>
    </section>
  );
}
