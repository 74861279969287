import { schema } from 'normalizr';
import { NumberLiteralType } from 'typescript';

export enum SubstepRequirement {
  None = 'none',
  One = 'one',
  All = 'all'
}

export enum ChecklistType {
  Fusion = 'Fusion',
  Injection = 'Injections'
}

export type HelpImage = {
  url: string;
  width: number;
  height: number;
  name: string;
};

export type StepEntity = {
  id: string;
  name: string;
  text: string;
  isComplete: boolean;
  parentId: string | null;
  steps: string[];
  substepRequirements: null | SubstepRequirement;
  help_image: HelpImage;
  help_text: string;
};

export const stepEntity = new schema.Entity(
  'steps',
  {},
  {
    processStrategy: (step) => ({
      ...step,
      isComplete: false
    })
  }
);

stepEntity.define({
  steps: [stepEntity]
});

export type SectionEntity = {
  id: number;
  name: string;
  steps: string[];
};

export const sectionEntity = new schema.Entity<SectionEntity>('sections', {
  steps: [stepEntity]
});

export type ChecklistEntity = {
  id: number;
  name: string;
  sections: number[];
  stepIds: number[];
};

export const checklistEntity = new schema.Entity<ChecklistEntity>(
  'checklists',
  {
    sections: [sectionEntity]
  },
  {
    processStrategy: (checklist) => {
      const stepIds = checklist.sections
        .map((section: SectionEntity) => section.steps)
        .flat()
        .map((step: StepEntity) => step.id);

      return {
        ...checklist,
        stepIds
      };
    }
  }
);

export type InjectionChecklistEntity = {
  id: number;
  name: string;
  sections: number[];
  stepIds: number[];
};

export const injectionChecklistEntity = new schema.Entity<InjectionChecklistEntity>(
  'injectionchecklists',
  {
    sections: [sectionEntity]
  },
  {
    processStrategy: (injectionchecklist = {}) => {
      let stepIds = [];
      
      if (injectionchecklist.sections) {
        stepIds = injectionchecklist.sections
          .map((section: SectionEntity) => section.steps)
          .flat()
          .map((step: StepEntity) => step.id);
      }

      return {
        ...injectionchecklist,
        stepIds
      };
    }
  }
);

export type ProviderEntity = {
  id: number;
  name: string;
  slug: string;
  policyName: string;
  checklist: number;
  injectionchecklist: number;
  stepIds: string[];
  injectionStepIds: string[];
};

export const providerEntity = new schema.Entity<ProviderEntity>(
  'providers',
  {
    checklist: checklistEntity,
    injectionchecklist: injectionChecklistEntity
  },
  {
    idAttribute: 'slug',
    processStrategy: (provider) => {
      const checklist = provider.checklist;
      const sections = checklist.sections;
      // Account for three levels of steps: Section -> Steps -> Substep and flatten to return
      const stepIds = sections
        .map((section: SectionEntity) => {
          return section.steps;
        })
        .flat()
        .map((step: any) => {
          return [
            step.id,
            step.steps.map((step: any) => {
              return [
                step.id,
                step.steps.map((substep: any) => {
                  return substep.id;
                })
              ].flat(2);
            })
          ].flat(2);
        })
        .flat();
        
        
      const injectionChecklist = provider.injectionchecklist;
      const injectionSections = injectionChecklist.sections ?? [];
      // Account for three levels of steps: Section -> Steps -> Substep and flatten to return
      const injectionStepIds = injectionSections
        .map((section: SectionEntity) => {
          return section.steps;
        })
        .flat()
        .map((step: any) => {
          return [
            step.id,
            step.steps.map((step: any) => {
              return [
                step.id,
                step.steps.map((substep: any) => {
                  return substep.id;
                })
              ].flat(2);
            })
          ].flat(2);
        })
        .flat();

      return {
        ...provider,
        stepIds,
        injectionStepIds
      };
    }
  }
);
