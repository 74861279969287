import axios from 'axios';
import { baseUrl } from 'api';
import { ObservableStore } from '@codewithdan/observable-store';

export class UserApi extends ObservableStore {
  LOCALUSERKEY = 'usr';

  initialState = {
    hasInit: true,
    invalid: false,
    emailSent: false,
    user: this.getLocalUser()?.user || null,
    accessToken: this.getLocalUser()?.jwt || null
  };

  // kick out the jams
  constructor() {
    super();
    axios.defaults.baseURL = baseUrl;

    // setup state by default
    const state = this.getState();
    if (state && state.hasInit) {
      this.setState(state, 'init');
    } else {
      this.setState(this.initialState, 'init');
    }
  }

  async login(_username, _pass) {
    // no reason to call if we don't have these
    if (!_username || !_pass) {
      return false;
    }

    const url = '/auth/local';
    return axios.post(url, { identifier: _username, password: _pass }).then(
      (response) => {
        // login is good, update state with user and auth token
        this.setState(
          {
            user: response.data.user,
            accessToken: response.data.jwt,
            invalid: false
          },
          'authUser'
        );
        this.setLocalUser(response.data);
        // TODO: setup GA user tracking here?
      },
      (error) => {
        // bad login, setting state.invalid to true to message via UI
        console.log('login error', error.toJSON());
        this.setState({ invalid: true });
      }
    );
  }

  async logout() {
    this.setState(this.initialState);
    // TODO: unset GA user track here?
  }

  async sendEmail(_slug, payload) {
    // no reason to call if we don't have these
    if (!_slug || !this.getState().accessToken) {
      return false;
    }
    this.setState({ emailSent: false });

    const url = `/providers/${_slug}/send`;
    const token = `Bearer ${this.getState().accessToken}`;

    const _payload = {
      countOfUnchecked: payload.stepsLeftUncheckedCount,
      patient: payload.patient,
      checked: payload.checkedSteps,
      recipient: payload.recipient,
      instructions: payload.instructions,
      subjectLine: payload.subjectLine,
      isFusion: payload.isFusion
    };

    return axios
      .post(
        url, _payload, { headers: { Authorization: token } }
      )
      .then(
        (response) => {
          this.setState({ emailSent: true });
          console.log('email sent.');
          //TODO: setup EMAIL tracking here?
        },
        (error) => {
          // bad login, setting state.invalid to true to message via UI
          console.log('email error', error.toJSON());
        }
      );
  }

  // get the local user in state
  getUser() {
    return this.getState().user;
  }

  // set the logged in user into localstorage
  setLocalUser(usrData) {
    if (this.ISSERVER) return null;
    const now = new Date();
    usrData.expiry = now.getTime() + 1000 * 60 * 60 * 10;
    localStorage.setItem(this.LOCALUSERKEY, JSON.stringify(usrData));
  }

  // get the logged in user into localstorage
  getLocalUser() {
    if (this.ISSERVER) return null;
    const localUsr = localStorage.getItem(this.LOCALUSERKEY);
    if (!localUsr) return null;
    const now = new Date();
    const user = JSON.parse(localUsr);
    if (now.getTime() > user.expiry) {
      localStorage.removeItem(this.LOCALUSERKEY);
      return null;
    }
    return user;
  }
}

export default new UserApi();
