import { Helmet } from 'react-helmet';
import styles from './Dashboard.module.scss';

type DashboardProps = {
	provider: any;
	selectFusionChecklistType: Function;
	selectInjectionChecklistType: Function;
};

export function Dashboard(props: DashboardProps) {
	return(
		<>
			<Helmet>
				<title>SI-BONE’s Interactive Payor Criteria Checklist Tool</title>
			</Helmet>
			
			<div className="row justify-content-center">
				<div className={styles.dashboardHeader}>
					<h1 className="mb-4 text-center">SI-BONE’s Interactive Payor Criteria Checklist Tool</h1>
					<p className={styles.dashboardHeaderDescription}>Payor-Specific Criteria for Sacroiliac (SI) Joint 	Procedures, including:</p>
					<ul className={styles.dashboardList}>
						<li><p className={styles.dashboardHeaderDescription}>Minimally Invasive SI Joint Fusion (iFuse Implants, iFuse 3D, and iFuse TORQ)</p></li>
						<li><p className={styles.dashboardHeaderDescription}>SI Joint Injections (Diagnostic and Therapeutic)</p></li>
					</ul>
				</div>
				<div className={styles.dashboardBoxContainer}>
					<div onClick={props.selectFusionChecklistType()} style={{cursor:"pointer"}}>
						<div className={styles.dashboardBox}>
							<h3 className={styles.dashboardTitle}>Minimally Invasive SI Joint Fusion</h3>
							<p className={styles.dashboardDescription}>Medical necessity criteria by payor for degeneration / SI joint dysfunction</p>
							<img
								width="174"
								height="175"
								src="/images/image-fusion.jpg"
								alt="Fusion"
							  />	
						</div>
					</div>
					{props.provider.injectionchecklist && 
						<div onClick={props.selectInjectionChecklistType()} style={{cursor:"pointer"}}>
							<div className={styles.dashboardBox}>
								<h3 className={styles.dashboardTitle}>SI Joint Injections</h3>
								<p className={styles.dashboardDescription}>Medical necessity criteria by payor for diagnostic and therapeutic SI joint injections</p>
								<img
									width="174"
									height="175"
									src="/images/image-injection.jpg"
									alt="Injection"
						  		/>	
							</div>
						</div>
					}
				</div>
				<div className="row mt-5">
					<div className="col">
					  <p className="text-center mb-0">
						Contact SI-BONE for more information: (800) 710-8511 |{' '}
						<a href="mailto:PICS@si-bone.com">PICS@si-bone.com</a> |{' '}
						<a href="https://si-bone.com/" target="_blank" rel="noreferrer">
						  si-bone.com
						</a>
					  </p>
					</div>
				  </div>
			</div>
		</>
	);
}