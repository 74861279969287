import { useState, useCallback } from 'react';

export function useInput(initialValue: string) {
  const [value, setValue] = useState<string>(initialValue);

  const handleChange = useCallback((event) => 
    { setValue(event.target.value) }, 
    [setValue]);

  return {
    handleChange,
    setValue,
    value
  };
}
